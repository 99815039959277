// extracted by mini-css-extract-plugin
export var actionCard = "card-module--actionCard--LABk7";
export var bubble = "card-module--bubble--ypkfd";
export var card = "card-module--card--Y8bfZ";
export var cardDark = "card-module--cardDark--LhwIq";
export var cardWhite = "card-module--cardWhite--aBI4i";
export var icon = "card-module--icon--g+QDD";
export var iconAbsolute = "card-module--iconAbsolute--ppilm";
export var iconLarge = "card-module--iconLarge--4bB-m";
export var iconMedium = "card-module--iconMedium--U2UOO";
export var iconSmall = "card-module--iconSmall--K3ecq";
export var img = "card-module--img--A0hit";
export var imgLarge = "card-module--imgLarge--TPqOb";
export var imgMedium = "card-module--imgMedium--dvEAM";
export var imgSmall = "card-module--imgSmall--dPz-K";