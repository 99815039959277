import { get } from 'lodash'
import React from 'react'
import Cookies from 'js-cookie'
import RootIndex from '../pages/index'

const Coupon = (props) => {
  const coupon = get(props, 'pageContext.coupon')

  React.useEffect(() => {
    const currentCoupon = Cookies.get('coupon')

    if (currentCoupon) {
      return
    }

    Cookies.set('coupon', coupon, { expires: 30 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <RootIndex />
}

export default Coupon
