import BottomCta from '@components/BottomCta'
import PopularQuestionList, {
  createQuestionItem,
} from '@components/custom/PopularQuestionList'
import FeatureList from '@components/features/FeatureList'
import FeatureRedirectList from '@components/features/FeatureRedirectList'
import HelmetHelper from '@components/HelmetHelper'
import Hero from '@components/Hero'
import Integrations from '@components/Integrations'
import Layout from '@components/Layout'
import SecurityList from '@components/SecurityList'
import Testimonials from '@components/Testimonials'
import { Categories, Config, Features, Routes } from '@constants'
import React from 'react'

const POPULAR_QUESTION_ITEMS = [
  createQuestionItem(
    'What is cloud-based automation?',
    `Our platform runs in the cloud. It means you don't need to use browser extensions to automate outreach on LinkedIn. It works automatically even when you are not online.`
  ),
  createQuestionItem(
    'Can I manage multiple LinkedIn accounts?',
    `Sure! You can add and manage an unlimited number of LinkedIn accounts. You'll be able to easily switch and share resources between connected LinkedIn accounts.`
  ),
  createQuestionItem(
    'Is it safe to use a LinkedIn Automation tool?',
    <>
      <div className="mb-3">
        {Config.NAME} combines several security measures and comply with
        LinkedIn rules resulting in a 0% ban rate. With each account,
        you&apos;ll get access to a unique IP address from your region, smart
        limits, automatic warm-up to ensure safety.
      </div>
      <div>
        {Config.NAME} runs safely in the cloud, based on your daily work
        schedule, and mimics human behavior to ensure all LinkedIn actions are
        made naturally without causing suspicion.
      </div>
    </>,
    Features.SAFETY.link
  ),
  createQuestionItem(
    'How to get more leads using a LinkedIn Automation tool?',
    <>
      <div className="mb-3">
        You can use LinkedIn Automation tools like {Config.NAME} to outreach at
        scale and turn more prospects into leads. To begin, create a prospect
        list of people in your target audience, and add them to your automated
        campaign.
      </div>
      <div>
        A sales campaign automatically sends personalized invitations and
        follow-ups while you focus on your business. You just collect positive
        replies and save hours of manual work with LinkedIn automation.
      </div>
    </>
  ),
  createQuestionItem(
    'Do I need LinkedIn Premium or Sales Navigator?',
    <>
      <div className="mb-3">
        {Config.NAME} is compatible with LinkedIn Basic, LinkedIn Premium, and
        Sales Navigator. You don&apos;t need LinkedIn Premium or Sales Navigator
        to use {Config.NAME}.
      </div>
      <div>
        However, you might run into a limited amount of visible search results
        if you often search using only a basic LinkedIn account.
      </div>
    </>
  ),
  createQuestionItem(
    `Can I integrate with our CRM or marketing platform?`,
    <>
      <div className="mb-3">
        Yes, you can easily sync data from {Config.NAME} to your CRM and push
        leads to your campaigns in {Config.NAME} via Zapier or MAKE
        (Integromat).
      </div>
      <div>
        Easily connect with tools in your existing workflow. Connect your
        account to 3000+ web apps, including Hubspot, Pipedrive, Copper,
        Salesforce, and Google Sheets through integration with just a few
        clicks.
      </div>
    </>,
    Routes.HELP.category(Categories.integrations)
  ),
  createQuestionItem(
    'How do I get charged exactly?',
    <>
      <div className="mb-3">
        You start with a 7-day free trial with all features included to test the
        platform, during which you can cancel anytime directly in your account.
      </div>
      <div>
        Afterward, you get charged per LinkedIn account on which you run
        automation. Visit pricing below to learn more.
      </div>
    </>,
    Routes.PRICING
  ),
  createQuestionItem(
    `Do I need to keep my browser open for ${Config.NAME} to work?`,
    `No, you don't have to, and that's one of the most significant advantages of using ${Config.NAME} as your LinkedIn automation tool. The software runs 100% in the cloud, based on your daily work schedule.`
  ),
]

const RootIndex = () => (
  <Layout hero={<Hero />}>
    <HelmetHelper
      title={`${Config.NAME}: #1 Cloud-based LinkedIn Automation Tool`}
      description={`Get 10x more conversions and personalize messages at scale with ${Config.NAME}. The safest cloud-based automation tool for LinkedIn outreach.`}
    />
    <div className="relative">
      <FeatureList />
      <Testimonials title="You're in good company" />
      <SecurityList />
      <Integrations />
      <BottomCta />
      <PopularQuestionList list={POPULAR_QUESTION_ITEMS} />
      <FeatureRedirectList />
    </div>
  </Layout>
)

export default RootIndex
