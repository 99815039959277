import React from 'react'
import imgHeroFull from '@static/hero/hero-full.svg'
import FeatureHeader from './features/FeatureHeader'

const Hero = () => {
  return (
    <FeatureHeader
      title={
        <>
          <div className="md:mr-20">
            Scale your Outreach with{' '}
            <span className="text-primary-main mt-2">#1 Cloud-based</span>{' '}
            LinkedIn Automation Tool
          </div>
        </>
      }
      subtitle={
        <div className="md:mr-10">
          Engage with leads and <b>get more replies</b> with safe LinkedIn
          outreach that delivers results.
        </div>
      }
      image={imgHeroFull}
      imageAlt="LinkedIn outreach campaign"
      buttonText="Try now - 7 days free"
    />
  )
}

export default Hero
